import React from 'react';
import {
    Group,
    Placeholder,
    Button,
    useAdaptivity,
    ViewWidth,
} from '@vkontakte/vkui';

import icon from '../../assets/vklogo.JPG';

const Onboarding = ({onFinish}) => {
    const {viewWidth} = useAdaptivity();
    const isMobile = viewWidth <= ViewWidth.MOBILE;

    return (
        <Group>
            <Placeholder
                icon={<img src={icon} width={100} height={100} alt="Логотип VK" />}
                header="Добро пожаловать в VKружке!"
                action={
                    <Button size="l" mode="primary" onClick={onFinish}>
                        Начать
                    </Button>
                }
                stretched={isMobile}
            >
                Находите единомышленников для активного отдыха, присоединяйтесь к интересным событиям или создавайте
                свои!
            </Placeholder>
        </Group>
    );
};

export default Onboarding;