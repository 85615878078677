import React, {useEffect, useState} from 'react';
import {
    Card,
    Header,
    Text,
    Avatar,
    Button,
    Subhead,
} from '@vkontakte/vkui';
import {Icon20UserOutline, Icon20PlaceOutline} from '@vkontakte/icons';
import bridge from "@vkontakte/vk-bridge";

const EventCard = ({event, onClick}) => {
    const [eventOwnerInfo, setEventOwnerInfo] = useState(null);
    const startDate = new Date(event.start_date).toLocaleString('ru-RU', {
        day: 'numeric',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit'
    });

    useEffect(() => {
        // Асинхронный запрос к VKWebAppGetUserInfo
        const fetchEventOwnerInfo = async () => {
            try {
                const data = await bridge.send("VKWebAppGetUserInfo", { user_id: event.creator.vk_id });
                setEventOwnerInfo(data); // Обновляем состояние с данными владельца
            } catch (error) {
                console.log(error); // Ошибка
            }
        };

        fetchEventOwnerInfo();
    }, [event.creator.vk_id]); // Зависимость от event.creator.vk_id

    // Если данные владельца еще не загружены, показываем загрузку
    if (!eventOwnerInfo) {
        return <div>Загрузка...</div>;
    }


    return (
        <Card mode="shadow" className="EventCard" onClick={() => onClick(event)}>
            <Header
                mode="primary"
                aside={<Text weight="1">{startDate}</Text>}
                before={<Avatar size={36} src={`${eventOwnerInfo.photo_100}`}/>}
                subtitle={
                    <div style={{display: 'flex', gap: '8px', marginTop: '6px'}}>
                        <Subhead weight="3" style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                            <Icon20UserOutline/>
                            {event.members.length} участников
                        </Subhead>
                        <Subhead weight="3" style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                            <Icon20PlaceOutline/>
                            {event.offices.map(office => office.name).join(', ')}
                        </Subhead>
                    </div>
                }
            >
                {event.name}
            </Header>
            <div style={{padding: '12px 16px'}}>
                <Text>{event.description}</Text>
            </div>
        </Card>
    );
};

export default EventCard;
