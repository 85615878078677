// src/App.js
import React, {useState, useEffect} from 'react';
import {
    View,
    Panel,
    PanelHeader,
    Group,
    SplitLayout,
    SplitCol,
    Epic,
    Tabbar,
    TabbarItem,
    ModalRoot,
    ConfigProvider,
    AdaptivityProvider,
    AppRoot,
    PanelHeaderBack,
    useAdaptivity,
    ViewWidth, Div, Title, Text
} from '@vkontakte/vkui';
import {
    Icon28CalendarOutline,
    Icon28UserCircleOutline,
    Icon28NewsfeedOutline,
    Icon28AddCircleOutline
} from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge';
import '@vkontakte/vkui/dist/vkui.css';

// Components
import Onboarding from './components/Onboarding/Onboarding';
import EventsList from './components/EventsList/EventsList';
import ApiClient from "./api/api";
import CreateEvent from "./components/CreateEvent/CreateEvent";
import EventDetails from "./components/EventDetails/EventDetails";

// API Service
// Constants
const PANELS = {
    ONBOARDING: 'onboarding',
    ALL_EVENTS: 'all_events',
    MY_EVENTS: 'my_events',
    EVENT_DETAILS: 'event_details',
    CREATE_EVENT: 'create_event'
};

const VIEWS = {
    MAIN: 'main',
};

const STORAGE_KEYS = {
    STATUS: 'vkrujke_onboarding_status',
};

const App = () => {
    const {viewWidth} = useAdaptivity();
    const [activePanel, setActivePanel] = useState(PANELS.ONBOARDING);
    const [activeView, setActiveView] = useState(VIEWS.MAIN);
    const [user, setUser] = useState(null);
    const [activeEvent, setActiveEvent] = useState(null);
    const [fetchedUser, setFetchedUser] = useState(null);
    const [showOnboarding, setShowOnboarding] = useState(true);
    const [joinedEvents, setJoinedEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    sessionStorage.setItem("X-Auth-Type", "vk");
    sessionStorage.setItem("X-Auth-Token", window.location.search.substring(1))

    const api = new ApiClient();

    useEffect(() => {
        // Инициализация VK Mini App
        bridge.send('VKWebAppInit');

        // Проверка статуса онбординга
        bridge.send('VKWebAppStorageGet', {
            keys: [STORAGE_KEYS.STATUS],
        }).then(({keys}) => {
            const onboardingCompleted = keys[0].value === 'completed';
            if (onboardingCompleted) {
                setShowOnboarding(false);
                setActivePanel(PANELS.ALL_EVENTS);
            }
        });

        // Получение данных пользователя
        async function fetchUser() {
            try {
                const userData = await bridge.send('VKWebAppGetUserInfo');
                setFetchedUser(userData);
                // Регистрация пользователя в вашей системе
                const name = userData.first_name + ' ' + userData.last_name;
                const registeredUser = await api.registerUser(name);
                setUser(registeredUser);
            } catch (error) {
                console.error('Failed to fetch user:', error);
                setError('Не удалось получить данные пользователя');
            }
        }

        fetchUser();
    }, []);

    // Загрузка событий пользователя
    useEffect(() => {
        if (user && activePanel === PANELS.MY_EVENTS) {
            const fetchJoinedEvents = async () => {
                setLoading(true);
                try {
                    const events = await api.getJoinedEvents();
                    setJoinedEvents(events);
                } catch (error) {
                    console.error('Failed to fetch joined events:', error);
                    setError('Не удалось загрузить события');
                } finally {
                    setLoading(false);
                }
            };
            fetchJoinedEvents();
        }
    }, [user, activePanel]);

    const finishOnboarding = async () => {
        try {
            // Сохраняем статус онбординга
            await bridge.send('VKWebAppStorageSet', {
                key: STORAGE_KEYS.STATUS,
                value: 'completed',
            });
            setShowOnboarding(false);
            setActivePanel(PANELS.ALL_EVENTS);
        } catch (error) {
            console.error('Failed to save onboarding status:', error);
        }
    };

    const handleEventClick = (event) => {
        setActiveEvent(event);
        setActivePanel(PANELS.EVENT_DETAILS);
    };

    const modals = (
        <ModalRoot>
            {/* Здесь будут модальные окна */}
        </ModalRoot>
    );

    return (
        <ConfigProvider>
            <AdaptivityProvider>
                <AppRoot>
                    <SplitLayout modal={modals}>
                        <SplitCol>
                            <Epic
                                activeStory={activeView}
                                tabbar={
                                    !showOnboarding && (
                                        <Tabbar>
                                            <TabbarItem
                                                onClick={() => setActivePanel(PANELS.ALL_EVENTS)}
                                                selected={activePanel === PANELS.ALL_EVENTS}
                                                text="Все события"
                                            >
                                                <Icon28NewsfeedOutline/>
                                            </TabbarItem>
                                            <TabbarItem
                                                onClick={() => setActivePanel(PANELS.MY_EVENTS)}
                                                selected={activePanel === PANELS.MY_EVENTS}
                                                text="Мои события"
                                            >
                                                <Icon28CalendarOutline/>
                                            </TabbarItem>
                                            <TabbarItem
                                                onClick={() => setActivePanel(PANELS.CREATE_EVENT)}
                                                selected={activePanel === PANELS.CREATE_EVENT}
                                                text="Создать событие"
                                            >
                                                <Icon28AddCircleOutline/>
                                            </TabbarItem>
                                        </Tabbar>
                                    )
                                }
                            >
                                <View id={VIEWS.MAIN} activePanel={activePanel}>
                                    {/* Onboarding Panel */}
                                    <Panel id={PANELS.ONBOARDING}>
                                        <PanelHeader>VKружке</PanelHeader>
                                        {showOnboarding && <Onboarding onFinish={finishOnboarding}/>}
                                    </Panel>

                                    {/* All Events Panel */}
                                    <Panel id={PANELS.ALL_EVENTS}>
                                        <PanelHeader>Все события</PanelHeader>
                                        <EventsList onEventClick={handleEventClick}/>
                                    </Panel>

                                    {/* My Events Panel */}
                                    <Panel id={PANELS.MY_EVENTS}>
                                        <PanelHeader>Мои события</PanelHeader>
                                        <EventsList
                                            events={joinedEvents}
                                            loading={loading}
                                            error={error}
                                            onEventClick={handleEventClick}
                                            isJoinedEvents={true}
                                        />
                                    </Panel>

                                    {/* Event Details Panel */}
                                    <Panel id={PANELS.EVENT_DETAILS}>
                                        <PanelHeader
                                            left={<PanelHeaderBack onClick={() => setActivePanel(PANELS.ALL_EVENTS)}/>}
                                        >
                                            {activeEvent?.name || 'Событие'}
                                        </PanelHeader>
                                        <EventDetails activeEvent={activeEvent} setActiveEvent={setActiveEvent}/>

                                    </Panel>
                                    <Panel id={PANELS.CREATE_EVENT}>
                                        <PanelHeader
                                            left={<PanelHeaderBack onClick={() => setActivePanel(PANELS.ALL_EVENTS)}/>}
                                        >
                                            Создать событие
                                        </PanelHeader>
                                        <CreateEvent
                                            setActiveEvent={setActiveEvent}
                                            setActivePanel={setActivePanel}
                                            panels={PANELS}
                                        />
                                    </Panel>
                                </View>
                            </Epic>
                        </SplitCol>
                    </SplitLayout>
                </AppRoot>
            </AdaptivityProvider>
        </ConfigProvider>
    );
};

export default App;