import React, {useState, useEffect} from 'react';
import {Group, Div, Text, Avatar, Spinner, Button} from '@vkontakte/vkui';
import bridge from '@vkontakte/vk-bridge';
import ApiClient from "../../api/api";

const EventDetails = ({activeEvent, setActiveEvent}) => {
    const api = new ApiClient();
    const [participantsInfo, setParticipantsInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isParticipant, setIsParticipant] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [chatLink, setChatLink] = useState(null);

    const getCurrentUserInfo = async () => {
        try {
            const data = await bridge.send("VKWebAppGetUserInfo");
            setCurrentUser(data);
        } catch (error) {
            console.error("Ошибка при получении информации о пользователе", error);
        }
    };

    const getUserInfo = async (userId) => {
        try {
            const data = await bridge.send("VKWebAppGetUserInfo", {user_id: userId});
            return data;
        } catch (error) {
            console.error(`Ошибка при загрузке информации о пользователе ${userId}`, error);
            return null;
        }
    };

    useEffect(() => {
        const initializeUser = async () => {
            await getCurrentUserInfo();
        };
        initializeUser();
    }, []);

    useEffect(() => {
        if (currentUser && activeEvent?.members) {
            const isUserInEvent = activeEvent.members.some((member) => member.vk_id === currentUser.id);
            setIsParticipant(isUserInEvent);

            // Получение ссылки на чат, если пользователь является участником
            if (isUserInEvent) {
                api.getEventChat(activeEvent.id)
                    .then((chat) => setChatLink(chat.chat_link))
                    .catch((error) => console.error("Ошибка при получении ссылки на чат:", error));
            }
        }
    }, [activeEvent, currentUser]);

    const fetchParticipantsInfo = async () => {
        if (!activeEvent?.members?.length) return;

        const participantsData = await Promise.all(
            activeEvent.members.map(async (member) => {
                const userInfo = await getUserInfo(member.vk_id);
                return {...member, userInfo};
            })
        );

        setParticipantsInfo(participantsData);
        setLoading(false);
    };

    const handleParticipation = async () => {
        try {
            if (isParticipant) {
                await api.leaveEvent(activeEvent.id);
                setIsParticipant(false);
                setChatLink(null); // Убираем ссылку на чат
            } else {
                await api.joinEvent(activeEvent.id);
                setIsParticipant(true);
                const chat = await api.getEventChat(activeEvent.id); // Запрашиваем чат после присоединения
                setChatLink(chat.chat_link);
            }

            let newActiveEvent = await api.getEvent(activeEvent.id);
            setActiveEvent(newActiveEvent);

            // Перезагрузить информацию о участниках после изменения статуса
            await fetchParticipantsInfo();
        } catch (error) {
            console.error("Ошибка при изменении статуса участия:", error);
        }
    };

    useEffect(() => {
        fetchParticipantsInfo();
    }, [activeEvent]);

    if (loading) {
        return <Spinner size="large"/>;
    }

    const openUserProfile = (userId) => {
        bridge.send("VKWebAppOpenProfile", {user_id: userId});
    };

    const chosenAppearance = isParticipant ? "negative" : "positive";

    console.log(isParticipant, chatLink);

    return (
        <Group>
            <Div>
                <Text weight="regular" style={{marginBottom: 16}}>
                    {activeEvent?.description}
                </Text>
                <Text weight="regular">Участники: {activeEvent?.members.length}</Text>
                <Text weight="regular">
                    Местоположение: {activeEvent?.offices.map((office) => office.name).join(", ")}
                </Text>

                <div style={{marginTop: 16}}>
                    <Text weight="regular">Список участников:</Text>
                    <div style={styles.participantsList}>
                        {participantsInfo.map((participant) => (
                            <div
                                key={participant.vk_id}
                                style={styles.participantCard}
                                onClick={() => openUserProfile(participant.vk_id)}
                            >
                                <div style={styles.avatarContainer}>
                                    <Avatar size={64} src={participant.userInfo?.photo_100}/>
                                </div>
                                <Text weight="regular" style={styles.participantName}>
                                    {participant.userInfo?.first_name} {participant.userInfo?.last_name}
                                </Text>
                            </div>
                        ))}
                    </div>
                </div>

                {isParticipant && chatLink && (
                    <Div style={styles.fixedChatButtonContainer}>
                        <Button
                            size="l"
                            mode="primary"
                            appearance="positive"
                            href={chatLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={styles.chatButton}
                        >
                            Открыть чат события
                        </Button>
                    </Div>
                )}

                <div style={styles.fixedButtonContainer}>
                    <Button
                        size="l"
                        onClick={handleParticipation}
                        appearance={chosenAppearance}
                        mode="primary"
                        style={styles.centeredButton}
                    >
                        {isParticipant ? "Покинуть ивент" : "Присоединиться"}
                    </Button>
                </div>
            </Div>

        </Group>
    );
};

const styles = {
    participantsList: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(80px, 1fr))",
        gap: "16px",
        justifyItems: "center",
    },
    participantCard: {
        textAlign: "center",
        cursor: "pointer",
        paddingTop: "10px"
    },
    avatarContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "8px",
    },
    participantName: {
        marginTop: "8px",
        textAlign: "center",
        fontSize: "14px",
    },
    fixedButtonContainer: {
        position: "fixed",
        bottom: "8%",
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
    },
    fixedChatButtonContainer: {
        position: "fixed",
        bottom: "12%",
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
    },
    centeredButton: {
        width: "85%",
        maxWidth: "400px",
    },
    chatButton: {
        marginBottom: "1%",
        width: "90%",
        maxWidth: "400px",
    }
};

export default EventDetails;
